export const AuthMethod = {
  Totp: 'craft\\auth\\methods\\TOTP',
  RecoveryCodes: 'craft\\auth\\methods\\RecoveryCodes',
};

export const statusColors = {
  success: {
    border: 'border-green-500',
    text: 'text-green-800 dark:text-green-200',
    background: 'bg-green-200 dark:bg-green-800',
  },
  info: {
    border: 'border-blue-500',
    text: 'text-blue-800 dark:text-blue-200',
    background: 'bg-blue-100 dark:bg-blue-800',
  },
  warning: {
    border: 'border-yellow-500',
    text: 'text-yellow-800 dark:text-yellow-200',
    background: 'bg-yellow-100 dark:bg-yellow-800',
  },
  danger: {
    border: 'border-red-400',
    text: 'text-red-800 dark:text-red-200',
    background: 'bg-red-100 dark:bg-red-800',
  },
};
