<template>
  <div class="app fixed inset-0 flex flex-col">
    <div
      class="fixed right-0 top-0 bottom-0 h-screen z-20 flex flex-col justify-center pointer-events-none"
    >
      <btn
        :href="mailToLink"
        kind="primary"
        class="relative pointer-events-auto -right-1 !p-2"
      >
        <icon class="w-7 h-7" label="Submit Report" icon="support" />
      </btn>
    </div>
    <div class="app-layout">
      <MainHeader class="app-header" />
      <div
        v-if="sidebarState !== 'hidden'"
        class="app-sidebar bg-gray-50 pb-4 md:pb-0 dark:bg-gray-800 border-b md:border-b-0 md:border-r border-gray-200 dark:border-black"
      >
        <div class="py-4">
          <SidebarItem :state="sidebarState">
            <router-link class="block" to="/">
              <ConsoleLogo />
            </router-link>
          </SidebarItem>
        </div>
        <nav>
          <NavList :items="visibleItems" :state="sidebarState" />
        </nav>
        <div
          class="mt-4 md:mt-auto py-2 text-sm bg-gray-50 dark:bg-gray-800 border-t text-light hidden md:block"
        >
          <SidebarItem :state="sidebarState">
            <ActionItem is="button" @click="toggle" :state="sidebarState">
              <template #prefix>
                <span
                  class="w-7 h-7 p-0 border rounded-lg inline-flex justify-center items-center"
                >
                  <Icon :icon="toggleIcon" class="w-4 h-4" />
                </span>
              </template>
              {{ toggleLabel }}
            </ActionItem>
          </SidebarItem>
        </div>
      </div>
      <main tabindex="-1" id="main" class="app-content">
        <router-view :key="route.fullPath" />
      </main>
    </div>
  </div>
  <TermsModal v-if="mustAcceptTerms" />
</template>

<script setup lang="ts">
  import {useStore} from 'vuex';
  import MainHeader from '@console/components/app/MainHeader.vue';
  import TermsModal from '@console/components/app/terms-modal/TermsModal.vue';
  import Btn from '@/common/ui/components/Btn.vue';
  import Icon from '@/common/ui/components/Icon.vue';
  import qs from 'qs';
  import {computed} from 'vue';
  import {useRoute} from 'vue-router';
  import {useSidebarStore} from '@console/composables/useSidebarStore';
  import ActionItem from '@/common/ui/components/ActionItem.vue';
  import ConsoleLogo from '@console/components/ConsoleLogo.vue';
  import NavList from '@/common/ui/components/NavList.vue';
  import {useNavigation} from '@console/composables/useNavigation';
  import SidebarItem from '@/common/ui/components/SidebarItem.vue';

  const store = useStore();
  const route = useRoute();
  const user = computed(() => store.state.account.user);
  const activeContext = computed(() => store.getters['context/active']);
  const {sidebarState, toggle} = useSidebarStore();
  const {visibleItems} = useNavigation();

  const toggleIcon = computed(() => {
    return sidebarState.value === 'expanded' ? 'chevron-left' : 'chevron-right';
  });

  const toggleLabel = computed(() => {
    return sidebarState.value === 'expanded'
      ? 'Collapse sidebar'
      : 'Expand sidebar';
  });

  const sidebarWidth = computed(() => {
    return sidebarState.value === 'expanded' ? '16.5rem' : '61px';
  });

  const mailToLink = computed(() => {
    const body = `

Please describe the issue you are experiencing and provide any additional information that may be helpful.

---
Additional Information:

Username: ${user.value?.username}
Active Account: ${activeContext.value?.title} (${activeContext.value?.id})
Email: ${user.value?.email}
Route: ${route.fullPath}`;

    const query = qs.stringify({
      subject: 'Support Request',
      body: body,
    });

    return `mailto:support@craftcms.com?${query}`;
  });

  const mustAcceptTerms = computed(() => {
    const excludedRouteNames = ['guest.cart', 'user.cart', 'org.cart'];

    return (
      !excludedRouteNames.includes(route.name as string) &&
      user.value &&
      user.value.termsAcceptedOn === null
    );
  });
</script>

<style scoped>
  .app-layout {
    --sidebar-width: v-bind(sidebarWidth);
    overflow: auto;

    @screen md {
      min-height: 100vh;
      display: grid;
      grid-template-areas: 'sidebar header' 'sidebar content';
      grid-template-rows: auto 1fr;
      grid-template-columns: var(--sidebar-width) 1fr;
    }
  }

  .app-header {
    grid-area: header;
  }

  .app-sidebar {
    @apply bg-gray-50 dark:bg-gray-800 border-r border-gray-200 dark:border-black;
    grid-area: sidebar;
    overflow-x: hidden;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }

  nav {
    overscroll-behavior: contain;
    scrollbar-width: none;
    overflow: auto;
  }

  .app-content {
    grid-area: content;
    overflow-x: hidden;
  }
</style>
