<template>
  <div
    class="py-2 pl-3 pr-2"
    :class="{
      'rounded-lg': rounded,
      [statusColors.success.background]: kind === 'success',
      [statusColors.info.background]: kind === 'info',
      [statusColors.warning.background]: kind === 'warning',
      [statusColors.danger.background]: kind === 'danger',
    }"
  >
    <div
      class="grid alert-content"
      :class="{
        'md:items-center': $slots.cta,
        ...textClasses,
      }"
    >
      <div class="flex md:items-center">
        <icon
          v-if="computedIcon"
          :icon="computedIcon"
          class="h-5 w-5 inline-flex mt-0.5"
          :label="computedIconLabel"
          :class="{
            ...textClasses,
          }"
        />
      </div>
      <div class="flex flex-col items-start gap-2 md:items-center md:flex-row">
        <p
          class="mb-0 text-sm leading-5 flex-1 pr-4"
          :class="{
            ...textClasses,
          }"
        >
          <slot />
        </p>
        <slot name="actions">
          <template v-if="$slots.cta">
            <AlertBtn
              :kind="kind"
              @click="$emit('click:cta')"
              :loading="ctaLoading"
              :disabled="ctaDisabled"
            >
              <slot name="cta" />
            </AlertBtn>
          </template>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from '@/common/ui/components/Icon.vue';
  import AlertBtn from '@/common/ui/components/AlertBtn.vue';
  import {statusColors} from '@console/helpers/constants';

  export default {
    components: {AlertBtn, Icon},
    computed: {
      statusColors() {
        return statusColors;
      },
      textClasses() {
        return {
          [this.statusColors.success.text]: this.kind === 'success',
          [this.statusColors.info.text]: this.kind === 'info',
          [this.statusColors.warning.text]: this.kind === 'warning',
          [this.statusColors.danger.text]: this.kind === 'danger',
        };
      },
      computedIcon() {
        if (this.icon) {
          return this.icon;
        }

        switch (this.kind) {
          case 'warning':
          case 'danger':
            return 'exclamation';
          default:
            return 'info-circle';
        }
      },
      computedIconLabel() {
        switch (this.kind) {
          case 'warning':
          case 'danger':
            return 'Warning';
          default:
            return null;
        }
      },
    },
    emits: ['click:cta'],
    props: {
      rounded: {
        type: Boolean,
        default: true,
      },
      kind: {
        type: String,
        validator(value) {
          return ['success', 'info', 'warning', 'danger'].includes(value);
        },
        default: 'info',
      },
      ctaDisabled: {
        type: Boolean,
        default: false,
      },
      ctaLoading: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: [String, Boolean],
        default: false,
      },
    },
  };
</script>

<style lang="pcss" scoped>
  .alert-content {
    grid-template-columns: 1.25rem 1fr;
    grid-gap: .75rem;
    text-align: left;
  }

  a, button {
    color: inherit;
  }
</style>
