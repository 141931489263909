<template>
  <component
    :is="computedComponent"
    class="c-btn"
    :target="target"
    :type="computedType"
    ref="root"
    :class="[
      {
        loading,

        // Base
        'ring-offset-2': true,
        'rounded-md': true,
        'border border-transparent': true,
        'no-underline hover:!no-underline': true,
        'disabled:opacity-50 disabled:cursor-default': true,
        'transition transition-duration-75': true,
        'opacity-50 cursor-default': disabled || loading,
        'justify-center': true,

        // Size
        'text-sm leading-3 px-3 py-2': size === 'xsmall',
        'text-sm leading-4  px-3 py-2': size === 'small',
        'text-sm leading-5 px-4 py-2': size === 'medium',
        'text-base leading-6 px-4 py-2': size === 'large',
        'text-sm leading-1 p-2': size === 'icon',

        // Variants

        // Contained button
        [[
          // Primary
          kind === 'primary'
            ? [
                'text-white hover:text-white',
                'border-blue-600 dark:border-blue-800 ',
                'bg-blue-600 dark:bg-blue-800 ',
                'disabled:bg-blue-500 disabled:border-blue-500 dark:disabled:border-blue-600 ',
                !disabled && !loading
                  ? 'hover:bg-blue-800 dark:hover:bg-blue-600 hover:border-blue-800 dark:hover:border-blue-600'
                  : '',
              ].join(' ')
            : null,

          // Secondary
          kind === 'secondary'
            ? [
                'text-black hover:text-black dark:text-white dark:hover:text-white',
                'border-gray-200 dark:border-gray-800 ',
                'bg-gray-200 dark:bg-gray-800 ',
                'disabled:bg-gray-200 disabled:border-gray-200 dark:disabled:bg-gray-800 dark:disabled:border-gray-800',
                !disabled && !loading
                  ? 'hover:bg-gray-300 dark:hover:bg-gray-700 hover:border-gray-300 dark:hover:border-gray-700'
                  : '',
              ].join(' ')
            : null,

          // Danger
          kind === 'danger'
            ? [
                'text-white hover:text-white',
                'border-red-600 dark:border-red-800 ',
                'bg-red-600 dark:bg-red-800',

                !disabled && !loading
                  ? 'hover:bg-red-700 dark:hover:bg-red-700 hover:border-red-700 dark:hover:border-red-700'
                  : '',
              ].join(' ')
            : null,

          // Success
          kind === 'success'
            ? [
                'text-white hover:text-white',
                'border-green-500 dark:border-green-600 ',
                'bg-green-500 dark:bg-green-600 ',
                'disabled:bg-green-500 disabled:border-green-500 dark:disabled:border-green-600 ',
                !disabled && !loading
                  ? 'hover:bg-green-700 dark:hover:bg-green-500 hover:border-green-700 dark:hover:border-green-500'
                  : '',
              ].join(' ')
            : null,
        ].join(' ')]: variant === 'contained',

        // Text button
        [[
          !disabled && !loading
            ? 'hover:bg-gray-500/5 dark:hover:bg-gray-300/5'
            : null,

          // Primary
          kind === 'primary' ? 'text-blue-600 dark:text-blue-400' : null,

          // Danger
          kind === 'danger' ? 'text-red-600 dark:text-red-400' : null,

          // Success
          kind === 'success' ? 'text-green-600 dark:text-green-400' : null,
        ].join(' ')]: variant === 'text',
      },
    ]"
    v-bind="additionalAttributes"
  >
    <template v-if="loading">
      <spinner />
    </template>

    <span
      class="inline-flex flex-nowrap justify-center items-center gap-1"
      :class="{
        invisible: loading,
      }"
    >
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
  import Spinner from '@/common/ui/components/Spinner.vue';
  import type {RouteLocationRaw} from 'vue-router';
  import {type Component, computed, ref} from 'vue';

  const props = withDefaults(
    defineProps<{
      type?: 'button' | 'submit' | 'reset' | 'menu';
      kind?: 'primary' | 'secondary' | 'danger' | 'success';
      size?: 'xsmall' | 'small' | 'medium' | 'large' | 'icon';
      variant?: 'contained' | 'text';
      disabled?: boolean;
      loading?: boolean;
      to?: RouteLocationRaw | string | null;
      href?: string | null;
      target?: '_blank' | '_self' | '_parent' | '_top' | null;
      component?: Component | string | null;
    }>(),
    {
      type: 'button',
      kind: 'secondary',
      disabled: false,
      size: 'medium',
      variant: 'contained',
      loading: false,
      to: null,
      href: null,
      target: null,
      component: null,
    }
  );

  const root = ref<HTMLElement | null>(null);
  defineExpose<{
    focus: () => void;
  }>();

  const additionalAttributes = computed(() => {
    const attrs: Record<string, any> = {};

    if (props.disabled) {
      attrs.disabled = true;
    }

    if (props.href) {
      attrs.href = props.href;
    }

    if (props.to) {
      attrs.to = props.to;
    }

    return attrs;
  });

  const computedComponent = computed(() => {
    if (props.component) {
      return props.component;
    }

    if (props.disabled && (props.to || props.href)) {
      return 'span';
    }

    if (props.to !== null && props.to !== '') {
      return 'router-link';
    }

    if (props.href !== null && props.href !== '') {
      return 'a';
    }

    return 'button';
  });

  const computedType = computed(() => {
    if (props.to !== null || props.href !== null) {
      return null;
    }

    return props.type;
  });

  // ESlint doesn't think this is used, but it is at various points in order to focus from an outer component
  // eslint-disable-next-line
  function focus() {
    root.value?.focus();
  }
</script>

<style lang="pcss">
  .c-btn,
  a.c-btn,
  button.c-btn {
    @apply inline-flex items-center;

    &.loading {
      @apply relative;

      .c-spinner {
        @apply absolute inset-0 flex justify-center items-center;
        color: currentColor;
      }
    }
  }
</style>
